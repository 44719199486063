<template>
	<S2SForm title="Upload Recon">
		<div
			id="fileUpload"
			:class="draggedOver"
			v-cloak
			@drop.prevent="dropFile"
			@dragover.prevent
			@dragenter="changeClass"
			@dragleave="changeClass"
			@click="selectFile"
		>
			<h3 class="text-center">Recons</h3>
			<div class="text-center">Drop file here or click to upload.</div>
			<input id="fileInput" style="visibility: hidden" type="file" @change="uploadFile" />
			<v-progress-linear v-if="uploadInProgress" indeterminate color="#4a90e2"></v-progress-linear>
		</div>
		<div v-if="files" class="text-center" align-baseline>
			<div class="subheading font-weight-medium">
				{{ files.name }}

				<span style="color: red" v-if="files.status === 'error'">{{ files.errorMessage }}</span>
				<span style="color: green" v-else>File Uploading</span>
			</div>
		</div>
	</S2SForm>
</template>

<script>
import Vue from "vue";

import { statement } from "../../api/statement-api";

export default Vue.extend({
	name: "UploadRecon",

	data: function() {
		return {
			files: null,
			draggedOver: "",
			uploadInProgress: false
		};
	},

	methods: {
		selectFile() {
			document.getElementById("fileInput").click();
		},
		async uploadFile(e) {
			this.files = e.target.files[0];
			this.uploadInProgress = true;

			const response = await statement.uploadRecon(this.files);

			if (response.status === 200) {
				this.$store.dispatch("notifications/success", response.data);
				this.uploadInProgress = false;
				this.files = null;
			}
		},
		async dropFile(e) {
			this.files = e.dataTransfer.files[0];
			this.uploadInProgress = true;
			const response = await statement.uploadRecon(this.files);
			if (response.status === 200) {
				this.$store.dispatch("notifications/success", response.data);
				this.uploadInProgress = false;
				this.files = null;
			}
			this.changeClass();
		},
		changeClass() {
			if (this.draggedOver === "") {
				this.draggedOver = "dragging";
			} else {
				this.draggedOver = "";
			}
		}
	}
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1,
h2 {
	font-weight: normal;
}

ul {
	list-style-type: none;
	padding: 0;
}

li {
	display: inline-block;
	margin: 0 10px;
}

a {
	color: #42b983;
}

#fileUpload {
	height: 20vh;
	border: 2px dashed #ccc;
	padding: 10px;
	border-radius: 5px;
}

#fileUpload.dragging {
	background: #e1f5fe repeating-linear-gradient(-45deg, #fafafa 25%, transparent 25%, transparent 50%, #fafafa 50%, #fafafa 75%);
	background-size: 40px 40px;
}
</style>
